import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Guideline = () => {
    return (
        <div>
            <style>
                {`
          #wrap {
            background-image: url('./src/img/book02.png');
          }
          .f {
            font-size: 1.2rem;
            font-weight: bold;
          }
          .f-title {
            width: 120px;
            text-align: center;
            background-color: #4999D4;
            color: #fff;
            border-color: #4999D4;
            cursor: default;
            padding: 5px;
          }
          .f-content {
            padding: 5px;
          }
          .f-bg {
            background-color: #C8E0E4;
          }
          ul {
            list-style-type: disc;
          }
        `}
            </style>

            <Container fluid className="mt-5" id="wrap">
                <Container className="pb-3">
                    <div className="my-5">&nbsp;</div>
                    <Container py-5>
                        <br />
                        <br />
                        <h1 style={{ fontFamily: 'NanumSquareBold' }}>공모요강</h1>
                    </Container>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">공모대상</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            전국 4세 ~ 12세 어린이 (초등학생 이하)
                        </Col>
                    </Row>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">공모기간</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            2023. 9. 25.(월) ~ 11. 10.(금)
                        </Col>
                    </Row>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">공모주제</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            함께 사는 우리는 2642 (이웃사이)
                            <br />
                            <span className="f-bg">
                                <small>
                                    &#8251; 층간소음 갈등을 줄이기 위해 노력하는 모습, 층간소음 예방
                                    실천방법 등
                                </small>
                            </span>
                        </Col>
                    </Row>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">출품규격</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            8절 도화지
                            <br />
                            <span className="f-bg">
                                <small>
                                    &#8251; 채색 재료에는 제한 없으며, 1인 1작품 접수 제한
                                </small>
                            </span>
                        </Col>
                    </Row>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">제출물</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            원본 그림, 참가신청서(서식), 개인정보 수집 및 이용 동의서(서식)
                            <br />
                            <span className="f-bg">
                                <small>
                                    &#8251; (서식위치) 공모전 홈페이지(www.2642campaign.or.kr) -
                                    [참가신청] - <Link to="/join">서식 다운로드</Link>
                                </small>
                                <br />
                                <small>
                                    &#8251; 참가신청서는 작성 후 원본 그림 뒷면에 부착하여 제출
                                </small>
                            </span>
                        </Col>
                    </Row>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">접수방법</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            등기우편 및 택배 접수
                            <br />
                            <span className="f-bg">
                                <small>&#8251;접수 비용 참가자 부담</small>
                            </span>
                        </Col>
                    </Row>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">접수처</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            서울 성동구 광나루로 320-2 YD빌딩 4층 층간소음상담지원센터
                        </Col>
                    </Row>

                    <Row className="row f mb-3">
                        <div className="col-12">
                            <div className="f-title">작품시상</div>
                            <br />
                        </div>
                        <Col xs={12} md={10}>
                            <Table
                                className="
                                striped
                                bordered
                                responsive"
                                style={{ textAlign: 'center' }}
                            >
                                <thead>
                                    <tr style={{ backgroundColor: '#A5D1EC !important' }}>
                                        <th rowspan="2" style={{ verticalAlign: 'middle' }}>
                                            구분
                                        </th>
                                        <th rowspan="2" style={{ verticalAlign: 'middle' }}>
                                            수상내역
                                        </th>
                                        <th colspan="2">아동부</th>
                                        <th colspan="2">초등부</th>
                                    </tr>
                                    <tr style={{ backgroundColor: '#A5D1EC !important' }}>
                                        <th>작품 수</th>
                                        <th>상금</th>
                                        <th>작품 수</th>
                                        <th>상금</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>대상</td>
                                        <td>환경부장관상</td>
                                        <td>1작품</td>
                                        <td>70만원</td>
                                        <td>1작품</td>
                                        <td>70만원</td>
                                    </tr>
                                    <tr>
                                        <td>최우수상</td>
                                        <td>한국환경공단이사장상</td>
                                        <td>1작품</td>
                                        <td>50만원</td>
                                        <td>1작품</td>
                                        <td>50만원</td>
                                    </tr>
                                    <tr>
                                        <td>우수상</td>
                                        <td>한국환경보전원장상</td>
                                        <td>2작품</td>
                                        <td>20만원</td>
                                        <td>2작품</td>
                                        <td>20만원</td>
                                    </tr>
                                    <tr>
                                        <td>참가상</td>
                                        <td colspan="5">
                                            추첨을 통해 층간소음 캐릭터 저감물품 20세트 제공
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6">총 8작품, 320만원 시상</td>
                                    </tr>
                                    {/* 나머지 행들 추가 */}
                                </tbody>
                            </Table>
                            <span>
                                <small>
                                    &#8251; 상금은 제세공과금 제외 후 수상자의 법정대리인에게 지급
                                </small>
                            </span>
                            <br />
                        </Col>
                    </Row>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">결과발표</div>
                        </Col>
                        <Col xs={12} md={10} className="f-content">
                            2023. 11. 21.(화) (예정)
                            <br />
                            <span className="f-bg">
                                <small>
                                    &#8251; 공모전 홈페이지 공지 및 수상자 개별 통지(예정)
                                </small>
                            </span>
                        </Col>
                    </Row>

                    <div className="row f">
                        <div className="col-12">
                            <br />
                            <div className="f-title">유의사항</div>
                            <br />
                        </div>
                        <div className="col-12">
                            <ul>
                                <li className="mb-1">
                                    공모 일정 등은 주최측의 사정으로 조정될 수 있으며, 이에 대해
                                    이의를 제기할 수 없다.
                                </li>
                                <li className="mb-1">
                                    출품된 작품에 대한 저작권은 출품자에게 귀속되며, 수상 후에도
                                    입상자에게 귀속됨을 원칙으로 한다.
                                </li>
                                <li className="mb-1">
                                    참가신청서 내 기입된 연락처로 연락이 불가능한 경우 심사 및 수상
                                    대상에서 제외될 수 있다.
                                </li>
                                <li className="mb-1">
                                    출품작은 타 공모전, 대회 등에서 수상한 적이 없는 순수
                                    창작물이어야 하며,
                                    <br /> 입상작으로 결정된 작품이라 하더라도 추후 이의신청 및 표절
                                    시비 발생 시 심사위원 회의 결과에 따라 입상이 취소될 수 있으며,
                                    <br />
                                    지급된 상장과 상금은 반환하여야 한다.
                                </li>
                                <li className="mb-1">
                                    출품자는 출품작이 제3자의 저작권, 소유권, 디자인보호법 등의 기타
                                    제반 권리를 침해하지 않도록 주의해야 하며,
                                    <br /> 향후 이로 인한 분쟁 발생 시 모든 책임은 출품자에게 있다.
                                </li>
                                <li className="mb-1">
                                    출품자는 공모전에 출품함과 동시에 추후 입상 시 환경부,
                                    한국환경보전원, 한국환경공단의 저작물(입상작) 이용을 허락한
                                    것으로 보고,
                                    <br /> 수상작의 저작재산권에 대한 이용료는 입상에 따른 상금으로
                                    대체할 수 있다.
                                </li>
                                <span className="f-bg">
                                    <small>
                                        &#8251; (사용범위) 수상작 선정일로 5년간 비영리·공익적
                                        목적의 SNS 홍보 및 굿즈 제작
                                    </small>
                                </span>
                                <li className="mb-1">
                                    심사 결과 심사 기준에 부합하는 입상자가 없을 경우 시상하지 않을
                                    수 있다.
                                </li>
                                <li className="mb-1">
                                    입상하지 않은 응모작의 반환은 응모자가 반환 청구해야하며, 이와
                                    관련하여 발생하는 비용(착불 배송비 등)은 응모자 부담으로 한다.
                                </li>
                                <span className="f-bg">
                                    <small>
                                        &#8251; 결과 발표 이후 홈페이지(www.2642campaign.or.kr)
                                        공지를 통해 반환 안내 예정
                                    </small>
                                </span>
                            </ul>
                        </div>
                    </div>

                    <Row className="f mb-3">
                        <Col>
                            <div className="f-title">문의처</div>
                        </Col>
                        <Col xs={12} className="f-content">
                            <ul>
                                <li className="mb-1">
                                    층간소음 예방 2642 그림 공모전 운영사무국(☎ 02-3407-1564)
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};

export default Guideline;
