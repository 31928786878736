import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import pdf from '../img/pdf.png';
import hwp from '../img/hwp.png';
import docx from '../img/docx.png';
import join1 from '../img/2642join1.jpg';
import join2 from '../img/2642join2.jpg';
const ApplyForm = () => {
    return (
        <div className="container-fluid mt-5" id="wrap">
            <style>
                {`
          #wrap {
            background-image: url('./src/img/book02.png');
          }
          .f {
            font-size: 1.2rem;
            font-weight: bold;
          }
          .f-title {
            width: 120px;
            text-align: center;
            background-color: #4999D4;
            color: #fff;
            border-color: #4999D4;
            cursor: default;
            padding: 5px;
          }
          .f-content {
            padding: 5px;
          }
          .f-bg {
            background-color: #C8E0E4;
          }
        `}
            </style>
            <Container className="pb-5">
                <div className="my-5">&nbsp;</div>
                <Container className="container-lg py-5">
                    <br /> <br />
                    <h1 style={{ fontFamily: 'NanumSquareBold' }}>참가신청</h1> <br />
                    <p style={{ fontSize: '26px' }}>
                        작성하신 서식을 그림작품과 같이 우편 또는 택배로 보내주세요.
                        <br />
                        <span className="f-bg">
                            <small>
                                &#8251; 접수처 : 서울 성동구 광나루로 320-2 YD빌딩 4층
                                층간소음상담지원센터
                            </small>
                        </span>
                        <br />
                        <span className="f-bg">
                            <small>&#8251; 접수 비용 참가자 부담</small>
                        </span>
                    </p>
                    <p className="fs-3"></p>
                </Container>
                <Row className="row f mb-3">
                    <Col>
                        <div className="f-title">서식 다운로드</div>
                    </Col>
                    <Col xs={12} md={10} className="f-content md-3">
                        참가신청서, 개인정보 수집 및 이용 동의서
                        <br /> <br />
                        <div>
                            <a href="./src/downloads/층간소음 예방 2642 그림 공모전 신청서식.hwp">
                                <img className="imgs " style={{ width: 100 }} src={hwp} alt="" />
                            </a>
                            <a href="./src/downloads/층간소음 예방 2642 그림 공모전 신청서식.docx">
                                <img className="imgs" style={{ width: 100 }} src={docx} alt="" />
                            </a>
                            <a
                                href="./src/downloads/층간소음 예방 2642 그림 공모전 신청서식.pdf"
                                download="층간소음 예방 2642 그림 공모전 신청서식"
                            >
                                <img className="imgs" style={{ width: 100 }} src={pdf} alt="" />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className="row f">
                    <Col>
                        <div className="f-title">서식 작성예시</div>
                    </Col>
                    <Col xs={12} md={10} className="f-content">
                        <img src={join1} style={{ width: '80%' }} alt="" />
                        <img src={join2} style={{ width: '80%' }} alt="" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ApplyForm;
