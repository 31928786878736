import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './font/font.css';
import Home from './screens/Home';
import Guideline from './screens/Guideline';
import FQA from './screens/FQA';
import Info from './screens/Info';
import Join from './screens/Join';
import Error404 from './screens/Error404';

import NavBarElements from './components/NavBarElements';
import Footer from './components/Footer';

function App() {
    return (
        <div className="App" style={{ fontFamily: 'MinSans' }}>
            <Router>
                <NavBarElements />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/guideline" element={<Guideline />} />
                    <Route path="/FAQ" element={<FQA />} />
                    <Route path="/info" element={<Info />} />
                    <Route path="/join" element={<Join />} />
                    <Route path="*" element={<Error404 />} />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
