import React from 'react';
import { Container } from 'react-bootstrap';

const Error404 = () => {
    return (
        <Container className="justify-content-center my-5">
            <h1>404</h1>
            <h3>Error 404 Not Found</h3>
        </Container>
    );
};

export default Error404;
