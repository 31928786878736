import React from 'react';
import { Container } from 'react-bootstrap';
import logo1 from '../img/logo1.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';

const Footer = () => {
    return (
        <div
            className="py-5"
            style={{
                borderTop: '1px solid rgb(215, 216, 216)',
                backgroundColor: 'rgb(245, 245, 245)',
            }}
        >
            <Container>
                <footer className="text-center text-lg-start">
                    <div className="container">
                        <section className="">
                            <div className="row m-3 justify-content-center">
                                <div className="col-10 col-lg-2" style={{}}>
                                    <img width="115vw" src={logo1} alt="" />
                                </div>
                                <div className="col-10 col-lg-2 mt-1">
                                    <img width="170vw" src={logo2} alt="" />
                                </div>
                                <div className="col-10 col-lg-2 ms-md-5">
                                    <img width="130vw" src={logo3} alt="" />
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="text-center p-3" style={{ color: '#00000F' }}>
                        ⓒ 2023-2024 THE VIRAL All rights reserved
                    </div>
                </footer>
            </Container>
        </div>
    );
};

export default Footer;
