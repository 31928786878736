import React from 'react';
import Poster from '../img/2642.png';
import { Container, Image } from 'react-bootstrap';

const Home = () => {
    return (
        <Container className="container p-5">
            <div>
                <Image className="w-100" src={Poster} />
            </div>
        </Container>
    );
};

export default Home;
