import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FQA = () => {
    return (
        <Container className="justify-content-center my-5">
            <h1 className="my-5">자주묻는 질문</h1>
            <div>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header> Q1. '층간소음'이 무엇인가요?</Accordion.Header>
                        <Accordion.Body>
                            <strong>
                                {'<'}층간소음이란{'>'}
                            </strong>{' '}
                            공동주택의 인접한 세대 간에 발생하는 소음으로
                            <br />
                            아이들 뛰는 소리, 발소리, 가구 끄는 소리, 피아노 소리, 오디오 소리, TV
                            소리 등을 총칭하여 부르고 있습니다.
                            <br /> <br />
                            <strong>
                                {'<'}층간소음의 종류{'>'}
                            </strong>{' '}
                            <br /> <strong> - 직접 충격 소음</strong>
                            : 뛰거나 걷는 동작 등으로 인하여 발생하는 소음 <br />
                            <strong> - 공기 전달 소음</strong> : 텔레비전, 음향기기 등의 사용으로
                            인하여 발생하는 소음
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Q2. 공모전의 주제가 무엇인가요?</Accordion.Header>
                        <Accordion.Body>
                            <strong>‘함께 사는 우리는 2642(이웃사이)’</strong> 라는 주제에 맞추어
                            공동주택에서 층간소음 갈등을 줄이기 위해 노력하는 모습,
                            <br />
                            층간소음을 예방하기 위한 방법 등을 그림으로 표현해 주세요.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Q3. 1인이 여러 작품을 응모해도 되나요?</Accordion.Header>
                        <Accordion.Body>
                            아니요. <strong>1인 1작 응모</strong>를 원칙으로 하고 있습니다.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Q4. 출품 규격이 어떻게 되나요?</Accordion.Header>
                        <Accordion.Body>
                            <strong>8절 도화지</strong> 크기이며, 채색 재료에는 제한이 없습니다
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Q5. 작품 접수는 어떻게 하나요?</Accordion.Header>
                        <Accordion.Body>
                            ① 홈페이지에서 제출서류(참가신청서, 개인정보 수집 및 이용 동의서)를 출력
                            후 내용을 기재해 주세요.
                            <br />
                            ② 작성하신 제출 서류는 원본 그림(8절 도화지)과 함께 공모전 운영
                            사무국으로 우편 또는 택배 접수해 주세요.
                            <br /> <br />
                            <strong>※ 운영 사무국 주소</strong> : 서울시 성동구 광나루로 320-2
                            YD빌딩 4층 층간소음상담지원센터 <br />
                            <strong>※ 제출 서류 다운로드</strong> :
                            <Link to={'/Join'}> 바로가기(클릭)</Link>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            Q6. 수상 결과는 어디서 확인할 수 있나요?
                        </Accordion.Header>
                        <Accordion.Body>
                            수상 결과는 2023.11.21.(화) 공모전 홈페이지 공지사항을 통해 발표
                            예정입니다. <br />
                            수상자분들에게는 시상식 참석 등을 위해 개별 안내 진행 예정입니다. <br />{' '}
                            <br />
                            <a className="red">※ 결과 발표 날짜는 사정에 의해 조정될 수 있음</a>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Q7. 제출한 작품 반환 가능한가요?</Accordion.Header>
                        <Accordion.Body>
                            응모작 중 수상하지 못한 작품에 한하여 반환 요청이 가능합니다. <br />
                            자세한 내용은 심사 결과 발표 이후 홈페이지 공지사항을 통해 안내
                            예정입니다. <br /> <br />
                            <a>
                                ※ 반환 요청 기간(23.12.2.~23.12.15.(예정))을 초과한 작품의 경우{' '}
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;작품의 소유권 이전에 동의한 것으로
                                간주하여 한국환경보전원에서 2개월간 보관 후 폐기됩니다.
                            </a>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </Container>
    );
};

export default FQA;
