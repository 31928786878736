import React from 'react';
import { Link } from 'react-router-dom';
import '../font/font.css';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../img/title01.png';
const NavBarElements = () => {
    return (
        <>
            <Navbar className="navbar navbar-expand navbar-light">
                <Container className="text-center">
                    <Link to={'/'}>
                        <img
                            width="80%"
                            class=""
                            alt="층간소음 예방 2642 캠페인을 함께하는 우리는 2642 (이웃사이)! 층간소음 갈등을 줄이기 위해 노력하는 모습 및 예방 실천방법을 그림으로 그려 공모전에
                참여해보세요!"
                            src={logo}
                        />
                    </Link>
                </Container>
            </Navbar>

            <Navbar
                className="navbar navbar-expand-lg navbar-Light bg-Light menu shadow"
                data-bs-theme="Light"
            >
                <Container className="mx-auto">
                    <Nav className="mx-auto navbar-nav justify-content-end " id="navbarNav">
                        <Link className="navbarMenu mx-4 nav-link navbgimg" to={'/'}>
                            2642 캠페인
                        </Link>
                        <Link
                            href="./Guideline"
                            className="mx-4 nav-link navbgimg"
                            to={'/Guideline'}
                        >
                            공모요강
                        </Link>
                        <Link href="./Join" className="mx-4 nav-link navbgimg" to={'/Join'}>
                            참가신청
                        </Link>
                        <Link href="./FAQ" className="mx-4 nav-link navbgimg" to={'FAQ'}>
                            FAQ
                        </Link>
                        <Link href="./Info" className="mx-4 nav-link navbgimg" to={'Info'}>
                            공지사항
                        </Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};
export default NavBarElements;
/*  */
