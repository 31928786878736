import React, { useState } from 'react';
import { Container, Table, Form, Button } from 'react-bootstrap';
import './css/info.css';

const Info = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMode, setSearchMode] = useState('default');
    const [searchResults, setSearchResults] = useState([]);
    const [postMode, setPostMode] = useState(false);
    const [postInfo, setPostInfo] = useState({});
    const [postList, setPostList] = useState([]);

    const handlePostClick = async (postId) => {
        // 클릭 시 동작 정의
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (searchTerm.trim() !== '') {
            // 검색 시 동작 정의
        }
    };

    return (
        <div className="container-fluid mt-5" id="wrap">
            <Container className="pb-xl-5">
                <div className="my-5">&nbsp;</div>
                <Container className="py-5">
                    <br /> <br />
                    <h1 style={{ fontFamily: 'NanumSquareBold' }}>공지사항</h1>
                </Container>

                {postMode && (
                    <Container className="mb-5">
                        <Table className="board-table lg" style={{ fontSize: '.7rem' }}>
                            <tbody>
                                <tr>
                                    <th scope="col" className="th-num tablebg">
                                        번호
                                    </th>
                                    <td className="tablewd">{postInfo.postid}</td>
                                    <th scope="col" className="th-date tablebg">
                                        등록일
                                    </th>
                                    <td className="tablewd">{postInfo.timestamp}</td>
                                    <th scope="col" className="th-hit tablebg">
                                        조회수
                                    </th>
                                    <td className="tablewd">{parseInt(postInfo.hit) + 1}</td>
                                </tr>
                                <tr>
                                    <th scope="col" className="th-title tablebg">
                                        제목
                                    </th>
                                    <td colSpan="5">{postInfo.title}</td>
                                    {/* </tr>  */}
                                    <tr>
                                        <th scope="col" className="th-title tablebg">
                                            본문
                                        </th>
                                        <td
                                            colSpan="5"
                                            style={{ textAlign: 'left', paddingLeft: '15px' }}
                                        >
                                            {postInfo.content && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: postInfo.content,
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                </tr>
                            </tbody>
                        </Table>
                        <style>
                            {`
                .tablebg {
                  background-color: rgb(245, 245, 245);
                  width: 150px;
                  text-align: center !important;
                }
                .tablewd {
                  width: 150px;
                }
              `}
                        </style>
                    </Container>
                )}

                <Container id="board-search" className="mb-5">
                    <div className="search-window">
                        <Form onSubmit={handleSearch}>
                            <div className="search-wrap mt-5">
                                <Form.Group controlId="search">
                                    <Form.Label className="blind">공지사항 내용 검색</Form.Label>
                                    <Form.Control
                                        type="search"
                                        name="title"
                                        placeholder="검색어를 입력해주세요."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </Form.Group>
                                <Button
                                    type="submit"
                                    variant="dark"
                                    style={{ backgroundColor: '#4d96d2' }}
                                >
                                    검색
                                </Button>
                            </div>
                        </Form>
                        {searchMode === 'none' && (
                            <div className="text-center">
                                <p>검색 결과가 없습니다.</p>
                            </div>
                        )}
                    </div>
                </Container>

                <Container id="board-list">
                    <Table className="board-table lg" style={{ fontSize: '.7rem' }}>
                        <thead>
                            <tr style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                <th scope="col" className="th-num" style={{ width: '5%' }}>
                                    번호
                                </th>
                                <th scope="col" className="th-title" style={{ width: '65%' }}>
                                    제목
                                </th>
                                <th scope="col" className="th-date" style={{ width: '20%' }}>
                                    등록일
                                </th>
                                <th scope="col" className="th-hit" style={{ width: '20%' }}>
                                    조회수
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchMode === 'default' || searchMode === 'none'
                                ? postList.map((post) => (
                                      <tr
                                          key={post.postid}
                                          onClick={() => handlePostClick(post.postid)}
                                      >
                                          <td>{post.postid}</td>
                                          <th>
                                              <a href={`/Info?post=${post.postid}`}>
                                                  {post.title}
                                                  {/* is_new 추가 */}
                                                  {post.is_new && (
                                                      <span>
                                                          <small style={{ color: '#ee3333' }}>
                                                              [new!]
                                                          </small>
                                                      </span>
                                                  )}
                                              </a>
                                          </th>
                                          <td>{new Date(post.timestamp).toLocaleDateString()}</td>
                                          <td>{post.hit}</td>
                                      </tr>
                                  ))
                                : searchResults.map((post) => (
                                      <tr
                                          key={post.postid}
                                          onClick={() => handlePostClick(post.postid)}
                                      >
                                          <td>{post.postid}</td>
                                          <th>
                                              <a href={`/Info?post=${post.postid}`}>
                                                  {post.title}
                                                  {/* is_new 추가 */}
                                                  {post.is_new && (
                                                      <span>
                                                          <small style={{ color: '#ee3333' }}>
                                                              [new!]
                                                          </small>
                                                      </span>
                                                  )}
                                              </a>
                                          </th>
                                          <td>{new Date(post.timestamp).toLocaleDateString()}</td>
                                          <td>{post.hit}</td>
                                      </tr>
                                  ))}
                        </tbody>
                    </Table>
                    <br /> <br />
                </Container>
            </Container>
        </div>
    );
};

export default Info;
